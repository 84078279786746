import * as React from "react"
import "./image-grid-item.css"

export interface ImageGridItemProps {
  title: string
}

export const ImageGridItem: React.FC<ImageGridItemProps> = ({
  children,
  title,
}) => (
  <div className="image-grid-item">
    {children}
    <h4 className="image-grid-item__title">{title}</h4>
  </div>
)
