import * as React from "react"
import "./contact-form.css"
import { FormEvent, useState } from "react"
import { Button } from "./button"
import PlacesAutocomplete from "react-places-autocomplete"
import classNames from "classnames"
import { Helmet } from "react-helmet"

const emailRegex =
  "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])).){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])"
const phoneRegex = "^(+d{1,2}s)?(?d{3})?[s.-]?d{3}[s.-]?d{4}$"

const fenceOptions = [
  "Privacy",
  "Privacy w/ Lattice",
  "2 Rail Ranch",
  "3 Rail Ranch",
  "4 Rail Ranch",
]

export const ContactForm: React.FC<
  React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >
> = ({ className }) => {
  const [address, setAddress] = useState("")

  return (
    <>
      <form
        className={classNames("contact-form", className)}
        name="RequestQuote"
        method="post"
        data-netlify
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="RequestQuote" />
        <select name="fenceType" required>
          <option value="">Select a fence type...</option>
          {fenceOptions.map(option => (
            <option key={option} label={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          required
          autoComplete="name"
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          autoComplete="email"
          pattern={emailRegex}
        />
        <input
          type="tel"
          name="phoneNumber"
          placeholder="Phone Number"
          required
          autoComplete="tel"
          pattern={phoneRegex}
        />
        <div className="autocomplete-container">
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={setAddress}
            googleCallbackName="initAutocomplete"
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <>
                <input
                  {...getInputProps({
                    placeholder: "Address",
                    className: "location-search-input",
                  })}
                  required
                  name="address"
                />
                <div
                  className="autocomplete-dropdown-container"
                  data-items={suggestions.length}
                >
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item"
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" }
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={suggestion.placeId}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </PlacesAutocomplete>
        </div>
        <Button color="secondary" type="submit">
          Request Quote
        </Button>
      </form>
      <Helmet>
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBTMiBJJrzU0NLhyATsbvVK2NQllw68urw&libraries=places&callback=initAutocomplete" />
      </Helmet>
    </>
  )
}
