import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
import { ContactForm } from "../components/contact-form"
import { Button } from "../components/button"
import repairSvg from "../images/construction_black_24dp.svg"
import supportSvg from "../images/contact_support_black_24dp.svg"
import fenceSvg from "../images/fence_black_24dp.svg"
import requestQuoteSvg from "../images/request_quote_black_24dp.svg"
import { ImageGrid } from "../components/image-grid"
import { ImageGridItem } from "../components/image-grid-item"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="value-prop">
      <h1>Build the fence of your dreams.</h1>
      <p className="subtitle">
        We want your business! Contact us for a free quote!
      </p>
      <a href="#quote">
        <Button type="button" color="secondary">
          Request Free Quote
        </Button>
      </a>
    </section>
    <section id="services" className="section services">
      <div className="section-content">
        <h2>Secure your property, protect what matters</h2>
        <p className="subtitle">
          A fence is more than just a barrier – it's protection and peace of
          mind for what matters most to you.
        </p>
        <div className="service-grid">
          <div className="service-grid__service">
            <img
              className="service-grid-service__icon"
              src={fenceSvg}
              alt="Install"
            />
            <h4 className="service-grid-service__title">Installation</h4>
            <div className="service-grid-service__description">
              From ordering materials to professional installers, we will handle
              all of the details of your fence.
            </div>
          </div>
          <div className="service-grid__service">
            <img
              className="service-grid-service__icon"
              src={repairSvg}
              alt="Repair"
            />
            <h4 className="service-grid-service__title">Repair</h4>
            <div className="service-grid-service__description">
              Did the Idaho wind damage your fence? We got you covered with
              quick repairs and quality parts.
            </div>
          </div>
          <div className="service-grid__service">
            <img
              className="service-grid-service__icon"
              src={supportSvg}
              alt="Support"
            />
            <h4 className="service-grid-service__title">Service Guarantee</h4>
            <div className="service-grid-service__description">
              We use high grade, commercial quality products. We stand behind
              the products we install.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="fence-options" className="section">
      <div className="section-content">
        <h2>Fence Options</h2>
        <p className="subtitle">Pick from our selection of fencing options</p>
        <ImageGrid>
          <ImageGridItem title="Privacy Fence">
            <StaticImage src="../images/privacy.png" alt="Privacy Fence" />
          </ImageGridItem>
          <ImageGridItem title="Privacy Fence with Lattice">
            <StaticImage
              src="../images/privacy-lattice.png"
              alt="Privacy Fence with Lattice"
            />
          </ImageGridItem>
          <ImageGridItem title="2 Rail Ranch Fence">
            <StaticImage src="../images/2-rail.png" alt="2 Rail Ranch Fence" />
          </ImageGridItem>
          <ImageGridItem title="3 Rail Ranch Fence">
            <StaticImage src="../images/3-rail.png" alt="3 Rail Ranch Fence" />
          </ImageGridItem>
          <ImageGridItem title="4 Rail Ranch Fence">
            <StaticImage src="../images/4-rail.png" alt="4 Rail Ranch Fence" />
          </ImageGridItem>
        </ImageGrid>
      </div>
    </section>

    <section id="quote" className="section quote">
      <div className="section-content">
        <div
          className="row s-col"
          style={{ alignItems: "center", marginTop: "2rem" }}
        >
          <div className="flex-1 column" style={{ alignItems: "center" }}>
            <img
              src={requestQuoteSvg}
              alt="Request Quote"
              style={{ width: 75, height: 75, marginBottom: "1rem" }}
            />
            <h2>Request a Quote</h2>
            <p>
              Fill out the form to request a free quote. We will use the address
              you provide, along with our extensive mapping data, in order to
              provide you with an accurate quote for your fencing needs.
            </p>
          </div>
          <ContactForm className="flex-1" />
        </div>
      </div>
    </section>

    <section id="about" className="section">
      <div className="section-content">
        <div className="row s-col" style={{ alignItems: "center" }}>
          <StaticImage
            className="flex-1"
            src="../images/rexburg.png"
            alt="Rexburg"
          />
          <div className="flex-1">
            <h2>About Us</h2>
            <p>
              We are a family owned business based out of Rexburg Idaho. Our
              specialty is vinyl privacy fences. Our main goal is to help you
              get the fence you want at a price you can afford! We strive for
              perfection and would love to earn your business! We service all of
              Southeastern Idaho. Call{" "}
              <a href="tel:208-932-8444">208-932-8444</a> today for a free in
              person estimate!
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
